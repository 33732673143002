
@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed:wght@300;400;500;600;700&display=swap');
.sidebar {
  height: 90vh;
}

/* .content-wrapper { height: 90vh; min-height: 90vh !important; overflow-y: auto; } */
.content-wrapper {
  height: calc(100vh - calc(3.5rem + 1px));
  overflow-y: auto;
}

.react-datepicker-wrapper, .react-date-picker { width: 100% !important }

.react-daterange-picker, .react-date-picker { height: 38px; width: 100%; }

.react-daterange-picker__wrapper, .react-date-picker__wrapper { border-radius: 4px; border: 1px solid #ced4da !important; padding: 0px 0px; background-color: #FFF; }

.react-date-picker__calendar { z-index: 5 }

.super-app-theme--header { background-color: #f5f5f5; }
.super-app-theme--header * {
  font-weight: bold !important;
}

